<template>
    <div class="row clearfix justify-content-center">
        <div class="col-xl-7 col-lg-8 col-md-10">
            <div class="card payment-success-card full-border">
                <div class="card-body py-5">
                    <div class="payment-summary-card">
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <h4 class="mb-4 text-apple-green font-weight-normal">Forbidden Error!</h4>
                            <p>You don't have permission to access this page.</p>
                            <!-- <div class="d-flex">
                                <a @click="$router.go(-1)" class="btn btn-outline-dark"><i class="fa fa-arrow-left"></i> <span>Go Back</span></a>
                                <router-link to="/dashboard/index" class="btn btn-apple-green ml-1"> <i class="fa fa-home"></i> <span>Home</span></router-link>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'403Component',
    mounted(){
        if(sessionStorage.getItem('settranid')){
            sessionStorage.removeItem("settranid");
            sessionStorage.removeItem('amt');
            sessionStorage.removeItem("currency");
            sessionStorage.removeItem('success');
            sessionStorage.removeItem('error');
            sessionStorage.removeItem('country');
        }
    }
}
</script>