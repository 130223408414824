<template>
  <div class="row clearfix justify-content-center">
    <div class="col-xl-7 col-lg-8 col-md-10">
      <div class="card payment-success-card full-border">
        <div class="card-body">
          <div class="transfers-list payment-summary-card">
            <div
              class="d-flex justify-content-center align-items-center flex-column"
            >
              <!-- <img src="@/assets/img/tick.png" alt="" /> -->
              <h4 class="my-4 text-apple-green font-weight-normal">
                Payment Preview
              </h4>
            </div>
            <ul class="list-unstyled price-summary">
              <li v-for="(info, i) in infromations" :key="i">
                <div class="d-flex justify-content-between">
                  <p class="mb-0">{{ info.key }}</p>
                  <label class="font-weight-bold">{{ info.value }}</label>
                </div>
              </li>
              <li>
                <div
                  class="d-flex flex-column flex-md-row justify-content-center mt-5"
                >
                  <router-link
                    to="/generic-payment/payment-options"
                    class="btn btn-secondary btn-lg btn-mw"
                    >Back</router-link
                  >
                  <button class="btn btn-lg btn-gradient btn-mw ml-0 ml-md-1 mt-1 mt-md-0" @click="mmPaymentsubmit()">
                    <!-- @click="PrintScreen()" -->
                    Confirm
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: "PaymentTransfer",
    data() {
        return {
            infromations: [
                // {
                //     key: "Transaction ID",
                //     value: 2154876564541,
                // },
                // {
                //     key: "Name",
                //     value: "Alizee Thomas",
                // },
                // {
                //     key: "Total Amount",
                //     value: "$250.00",
                // },
                // {
                //     key: "Date Time",
                //     value: "March 21 2022, 10:00AM",
                // },
            ],
            namecheckdetails:''
        };
    },
    methods: {
        PrintScreen() {
            window.print();
        },
        userLogin() {
            let loginData = {
                email: "sucheta@mailinator.com",
                password: "Admin@123",
            };
            this.$http
            .post("https://standapp.com/api/v1/business/auth/login", loginData)
            .then((res) => {
                if (res.data.code == "0004-000") {
                    this.userToken = res.data.token;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        previewinformation(){
            this.namecheckdetails = JSON.parse(localStorage.getItem('namecheckdetails'));
            this.infromations = [
                {
                    key: "Name",
                    value: this.namecheckdetails.name, //"Alizee Thomas",
                },
                {
                    key: "Account",
                    value: this.namecheckdetails.accountnumber, //"Alizee Thomas",
                },
                {
                    key: "Total Amount",
                    value: "$250.00",
                },
                {
                    key: "Date Time",
                    value: "March 21 2022, 10:00AM",
                },
            ];
        },
        mmPaymentsubmit(){
            const paymentData = JSON.parse(localStorage.getItem('mmpaymentDetail'));
            console.log(paymentData);
        }
    },mounted() {
        this.previewinformation();
        this.userLogin();
    },
};
</script>