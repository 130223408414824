<template>
    <div class="main-loader" v-if="loading">
        <div class="loader">
            <div>
            <img src="@/assets/main_logo.svg" width="200" alt="PayAngel" />
            <p>Please wait...</p>
            </div>
        </div>
        <!-- <div class="loader-cnt">
            <img src="@/assets/main_logo.svg" width="200" alt="PayAngel">
            <p class="mb-0">Please wait...</p>
            <h3>Processing Payment</h3><br/>
            <p class="mb-0">Please standby while we process your payment</p>
        </div> -->
    </div>

</template>
<script>
export default {
    name:"LoaderComponent",
    data(){
        return{
            loading:false
        }
    },methods: {
        show(){
            this.loading = true
        },

        hide(){
            this.loading = false
        }
    }
}
</script>
<style lang="scss">
/* .main-loader {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main-loader .loader-cnt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: white;
    padding: 30px 90px;
    border-radius: 0.25rem;
} */

.main-loader {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba($color: #f1f1f1, $alpha: 0.8);
    p {
        margin: 0;
        color: var(--midnight-blue);
    }
    .loader {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 100%;
        text-align: center;
    }
}
</style>