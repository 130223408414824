<template>
  <div class="row clearfix justify-content-center">
    <div class="col-xl-7 col-lg-8 col-md-10">
      <div class="card payment-success-card full-border">
        <div class="card-body">
          <div class="transfers-list payment-summary-card">
            <div class="d-flex justify-content-center align-items-center flex-column">
              <img src="@/assets/img/tick.png" alt="" />
              <h4 class="my-4 text-apple-green font-weight-normal">
                Payment Success
              </h4>
            </div>
            <ul class="list-unstyled price-summary">
              <li>
                <div class="d-flex justify-content-between">
                  <p class="mb-0">Transaction ID</p>
                  <label class="font-weight-bold">{{ tranid }}</label>
                </div>
              </li>
              <li>
                <div class="d-flex justify-content-between">
                  <p class="mb-0">Amount</p>
                  <label class="font-weight-bold">{{ amount }}</label>
                </div>
              </li>
              <li>
                <p class="text-center">You are being redirected to the merchant. Please don't close this page. If the page does not redirect , click on the Ok button</p>
                <div class="d-flex flex-column flex-md-row justify-content-center mt-5" >
                    <button class="btn btn-lg btn-gradient btn-mw ml-0 ml-md-1 mt-1 mt-md-0" @click="paymentok()">
                        Ok
                    </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SuccessComponent",
  data() {
      return {
        tranid:'',
        amount:''
      };
  },
  methods: {
    paymentok(){
      const successurl = sessionStorage.getItem('success');
      const tranid = sessionStorage.getItem('settranid');
      const encodetranid = btoa(tranid); 
      const redirecturl = successurl+'?contents='+encodetranid;
      // console.log(redirecturl);
      // console.log(successurl);
      sessionStorage.removeItem("settranid");
      sessionStorage.removeItem('amt');
      sessionStorage.removeItem("currency");
      sessionStorage.removeItem('success');
      sessionStorage.removeItem('error');
      sessionStorage.removeItem('country');
      window.location.href = redirecturl;
      // this.$router.push({ path: successurl });
    },

    pageRedirect() {
      console.log('page redirect');
      const successurl = sessionStorage.getItem('success');
      const tranid = sessionStorage.getItem('settranid');
      const encodetranid = btoa(tranid); 
      const redirecturl = successurl+'?contents='+encodetranid;
      // console.log(redirecturl);
      // console.log(successurl);
      sessionStorage.removeItem("settranid");
      sessionStorage.removeItem('amt');
      sessionStorage.removeItem("currency");
      sessionStorage.removeItem('success');
      sessionStorage.removeItem('error');
      sessionStorage.removeItem('country');
      window.location.replace(redirecturl);
    },

    createLog(transactionid){
      let addLog = {
        transactionid : transactionid,
        pagename : 'Payment Success'
      }
      this.$http.post(this.$standappurl + "payment/create/activitylog", addLog).then((response) => {
        console.log(response.data.code);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, mounted(){
    if(sessionStorage.getItem("settranid")){
      this.createLog(sessionStorage.getItem("settranid"));
      this.tranid = sessionStorage.getItem("settranid");
      this.amount = sessionStorage.getItem("currency")+' '+sessionStorage.getItem("amt")
      setTimeout(this.pageRedirect(), 15000);
    } 
    else {
      console.log('session clear');
      this.$router.push({ path: '/session-expired' });
    }
  }
};
</script>